//  调配在线主页
<template>
  <div>
    <Header :userlist="userlist" @changeState="changeState" />
    <div class="wrapper">
      <div class="wrap">
        <Mains v-if="state === 0" :userlist="userlist" @changeState="changeState" />
        <UserSet v-if="state === 1" :navTab="navTab" @change="change" @changeState="changeState" />
        <Wallet v-if="state === 2" />
        <Service v-if="state === 3" />
        <Online v-if="state === 4" title="调配在线" timeType="调配工作时间" @changeState="changeState" />
        <AuditRecord
          v-if="state === 5"
          :recordList="recordList"
          @changeState="changeState"
          @changePage="changePage"
          :showPage="showPage"
          :total="total"
        />
        <SysMessage v-if="state === 6" />
        <RecordInfo v-if="state === 7" :pCode="pCode" :type="4" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./header";
import Footer from "@/components/public/public_bottom";
import Mains from "./main"; //主页
import UserSet from "@/components/personal_repect/userData"; // 修改个人信息
import Wallet from "@/components/personal_repect/wallet/wallet"; // 我的钱包
import Service from "@/components/personal_repect/service"; // 联系客服
import Online from "@/components/personal_repect/online"; // 调配在线
import AuditRecord from "@/components/personal_repect/auditRecord"; // 审核记录
import SysMessage from "@/components/personal_repect/sysMessage";
import RecordInfo from "@/components/personal_repect/recordInfo"; // 审核详情

import { usergetinfo } from "@/request/user";
import { getDoctorAuditList } from "@/request/personnel";
export default {
  components: {
    Header,
    Footer,
    Mains,
    Online,
    UserSet,
    AuditRecord,
    Wallet,
    Service,
    SysMessage,
    RecordInfo
  },
  data() {
    return {
      state: 0,
      navTab: 0,
      userlist: {},
      recordList: [],
      pCode: "",
      total: 0,
      showPage: false
    };
  },
  created() {
    if (this.$route.query.state) {
      this.state = parseInt(this.$route.query.state);
    }
    usergetinfo({ token: this.$token }).then(res => {
      this.userlist = res.data;
    });
    this.getPageAuditList(1);
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.query.msg) {
        vm.state = 6;
      }
    });
  },
  methods: {
    getPageAuditList(n) {
      getDoctorAuditList({
        token: this.$token,
        page: n
      }).then(res => {
        if (res.code == 1) {
          this.recordList = res.data.data;
          this.total = res.data.total;
          this.showPage = res.data.showPage;
        }
      });
    },
    changeState(num, pCode) {
      this.state = num;
      if (num === 2 && this.state === 2) {
        this.state = 100;
        this.$nextTick(() => {
          this.state = 2;
        });
      }
      if (num == 7) {
        this.pCode = pCode;
      }
    },
    change(num) {
      this.navTab = num;
    },
    changePage(n) {
      this.changePage(n);
    }
  }
};
</script>

<style scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.wrapper {
  background: #f5f5f5;
  padding: 40px 0px;
}
</style>